/*
COLOREXTRACT
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: Aug 6, 2024
UPDATE DATE: Aug 6, 2024
REVISION: 1
NAME: index.scss
TYPE: Cascading Style Sheet
DESCRIPTION: ColorExtract | Extract color with your style!
PALETTE: -
*/

a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,dialog,div,dl,dt,em,embed,fieldset,figcaption,figure,font,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,hr,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,meter,nav,object,ol,output,p,pre,progress,q,rp,rt,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video,xmp{border:0;margin:0;padding:0;font-size:100%}html,body{height:100%}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}b,strong{font-weight:bold}img{color:transparent;font-size:0;vertical-align:middle;-ms-interpolation-mode:bicubic}ol,ul{list-style:none}li{display:list-item}table{border-collapse:collapse;border-spacing:0}th,td,caption{font-weight:normal;vertical-align:top;text-align:left}q{quotes:none}q:before,q:after{content:'';content:none}sub,sup,small{font-size:75%}sub,sup{line-height:0;position:relative;vertical-align:baseline}sub{bottom:-0.25em}sup{top:-0.5em}svg{overflow:hidden}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Regular.woff2') format('woff2'),
       url('./assets/font/Exo-Regular.woff') format('woff'),
       url('./assets/font/Exo-Regular.ttf') format('ttf'),
       url('./assets/font/Exo-Regular.eot') format('eot'),
       url('./assets/font/Exo-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Bold.woff2') format('woff2'),
       url('./assets/font/Exo-Bold.woff') format('woff'),
       url('./assets/font/Exo-Bold.ttf') format('ttf'),
       url('./assets/font/Exo-Bold.eot') format('eot'),
       url('./assets/font/Exo-Bold.svg') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Light.woff2') format('woff2'),
       url('./assets/font/Exo-Light.woff') format('woff'),
       url('./assets/font/Exo-Light.ttf') format('ttf'),
       url('./assets/font/Exo-Light.eot') format('eot'),
       url('./assets/font/Exo-Light.svg') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

html{
  text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body{
  background: #FFF;
  color: #999;
  font-size: 12px;
  line-height: 12px;
  font-family: 'Exo', Helvetica, Arial, sans-serif;
  margin: 0;
  font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

a{
  text-decoration: none;
  color: #666;
  transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;

  &:hover{
    color: #333;
  }
}

// a[disabled]{
//   pointer-events: none;
//   opacity: .5;
// }

button,
input{
  display: block;
  width: 100%;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-family: 'Exo', Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  font-size: 12px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;

  // &:hover{}

  &:focus{
    outline: none;
  }
}

button{
  cursor: pointer;
  
  *{
    pointer-events: none;
  }
}

.rc-slider{
  .rc-slider-rail{
    height: 12px;
    border-radius: 3px;
    background: #EEE;
    border: 1px solid rgba(255, 255, 255, 1);
    overflow: hidden;
  }

  .rc-slider-track{
    height: 10px;
    margin: 1px 0 0 1px;
    border-radius: 3px;
    background: #999;
  }

  .rc-slider-handle{
    width: 22px;
    height: 22px;
    border-radius: 5px;
    border: 2px solid #666;
    // border: none;
    background: transparent;
    opacity: 1;
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, .4);
    transition: background 200ms ease-in-out, box-shadow 200ms ease-in-out;
    -moz-transition: background 200ms ease-in-out, box-shadow 200ms ease-in-out;
    -webkit-transition: background 200ms ease-in-out, box-shadow 200ms ease-in-out;

    &.rc-slider-handle-dragging{
      box-shadow: none;
      border: 2px solid #666;
    }

    &:focus-visible{
      border: 2px solid #666;
      box-shadow: none;
    }

    &:hover{
      border: 2px solid #666;
      box-shadow: 0 5px 10px -5px rgba(0, 0, 0, .8);
    }

    &:before{
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 2px;
      height: 100%;
      background: #666;
    }
  }
}

header{
  padding: 15px 20px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background: rgba(255, 255, 255, .95);
  z-index: 3;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  box-shadow: 0 20px 20px -10px rgba(0, 0, 0, .1);

  > div{
    > a{
      display: block;
      float: left;
      font-size: 20px;
      line-height: 40px;
      font-weight: bold;
      margin: 5px 0;

      > svg{
        display: block;
        width: 164px;
        height: 40px;
      }
    }

    > ul{
      margin: -5px -5px -5px 174px;
      overflow: auto;

      > li{
        position: relative;
        float: left;
        padding: 5px;
        box-sizing: border-box;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4){
          width: 19%;
        }

        &:nth-child(5),
        &:nth-child(6){
          width: 12%;
        }

        .slider{
          border: 1px solid #CCC;
          height: 40px;
          border-radius: 5px;
          padding: 8px 48px 8px 14px;
          background: #FFF;
          font-size: 14px;
          box-sizing: border-box;

          > span{
            width: 40px;
            text-align: right;
            position: absolute;
            right: 14px;
            top: 50%;
            transform: translate(0, -50%);
            font-size: 14px;
            line-height: 40px;
            color: #000;
          }
        }

        .unit{
          position: absolute;
          top: 5px;
          right: 5px;
          height: 40px;
          width: 40px;
          text-align: center;
          line-height: 40px;
          background: #CCC;
          color: #FFF;
          border-radius: 0 5px 5px 0;
          font-size: 14px;
          font-weight: bold;
          transition: all 200ms ease-in-out;
          -moz-transition: all 200ms ease-in-out;
          -webkit-transition: all 200ms ease-in-out;
        }

        label{
          position: absolute;
          top: 1px;
          left: 16px;
          background: #FFF;
          padding: 0 5px;
          font-size: 11px;
          line-height: 11px;
        }
  
        input{
          border: 1px solid #CCC;
          height: 40px;
          border-radius: 5px;
          padding: 4px 55px 0 15px;
          background: #FFF;
          font-size: 14px;
  
          &.no-unit{
            padding: 4px 15px 0 15px;
          }
  
          &:hover,
          &:focus{
            border: 1px solid #999;
  
            ~ .unit{
              background: #999;
            }
          }
        }
  
        button{
          background: #666;
          color: #FFF;
          border-radius: 5px;
          height: 40px;
          padding: 0 10px;
          font-weight: bold;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
  
          &:hover{
            background: #333;
          }
  
          span{
            display: block;
            font-size: 9px;
            font-weight: normal;
            margin: 0 0 1px 0;
          }
  
          > svg{
            display: inline-block;
            vertical-align: middle;
            margin: -3px 5px 0 0;
            width: 14px;
            height: 14px;
          }
        }
      }
    }
  }
}

.container{
  padding: 90px 20px 20px 20px;
  
  &.area{
    > ul{
      margin: 0;
  
      > li{
        padding: 0;
      }
    }

    > div{
      padding-top: 1px;
    }
  }

  > ul{
    position: relative;
    z-index: 1;
    margin: -.5px;
    text-align: center;

    > li{
      float: left;
      padding: .5px;
      margin: 0;
      box-sizing: border-box;
      transition: all 200ms ease-in-out;
      -moz-transition: all 200ms ease-in-out;
      -webkit-transition: all 200ms ease-in-out;

      &.big{
        width: 100% !important;

        > button{
          height: auto;

          &:hover{
            transform: scale(1);
          }

          > ul{
            position: static;
            margin: 220px 20px 20px 20px;

            > li{
              font-size: 14px;
              line-height: 18px;

              &:nth-child(1){
                font-size: 26px;
                font-weight: 100;
                line-height: 30px;
              }
            }
          }
        }
      }

      > button{
        position: relative;
        height: 150px;

        &:hover{
          transform: scale(1.1);
          z-index: 2;
          box-shadow: 0 20px 20px -10px rgba(0,0,0,.25);
        }

        > ul{
          position: absolute;
          bottom: 10px;
          left: 10px;
          right: 10px;
          background: rgba(0, 0, 0, .25);
          margin: 0;
          padding: 10px;
          transition: all 200ms ease-in-out;
          -moz-transition: all 200ms ease-in-out;
          -webkit-transition: all 200ms ease-in-out;

          > li{
            padding: 1px 5px;
            color: #FFF;
            text-align: left;
            font-weight: bold;
            line-height: 16px;
            box-sizing: border-box;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            
            &:first-child{
              font-weight: normal;
              font-size: 16px;
              line-height: 20px;
            }

            &:nth-child(2){
              text-transform: uppercase;
            }

            ul.color-info{
              margin: -5px;
              padding: 15px 0 0 0;
              font-size: 10px;
              line-height: 14px;
              color: #FFF;
              overflow: auto;
    
              li{
                float: left;
                padding: 5px;
                font-weight: bold;
                text-align: left;
                white-space: nowrap;
                box-sizing: border-box;

                > ul{
                  margin: -5px;
                }

                a{
                  pointer-events: all;
                  color: #FFF;

                  > svg{
                    display: inline-block;
                    vertical-align: middle;
                    margin: -2px 0 0 0;
                  }
                }
    
                span{
                  display: block;
                  font-weight: normal;
                }
    
                .colorname{
                  display: inline-block;
                  vertical-align: middle;
                  margin: -1px 3px 0 0;
                  width: 9px;
                  height: 9px;
                  border: 1px solid rgba(255, 255, 255, .5);
                }
    
                .chart{
                  height: 15px;
                  background: rgba(255, 255, 255, .5);
                  padding: 1px;
                  margin: 5px 0 0 0;
                  box-sizing: border-box;
                  
                  > span{
                    position: relative;
                    display: block;
                    height: 13px;
                    transition: all 200ms ease-in-out;
                    -moz-transition: all 200ms ease-in-out;
                    -webkit-transition: all 200ms ease-in-out;
    
                    .pointer{
                      position: absolute;
                      top: -4px;
                      bottom: -4px;
                      width: 1px;
                      background: #FFF;
                      transform: translate(-.5px, 0);
                      transition: all 200ms ease-in-out;
                      -moz-transition: all 200ms ease-in-out;
                      -webkit-transition: all 200ms ease-in-out;
                    }
    
                    &.huepicker{
                      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ff0000+0,ffff00+17,00ff00+33,00ffff+50,0000ff+67,ff00ff+83,ff0000+100 */
                      background: rgb(255,0,0); /* Old browsers */
                      background: -moz-linear-gradient(left,  rgba(255,0,0,1) 0%, rgba(255,255,0,1) 17%, rgba(0,255,0,1) 33%, rgba(0,255,255,1) 50%, rgba(0,0,255,1) 67%, rgba(255,0,255,1) 83%, rgba(255,0,0,1) 100%); /* FF3.6-15 */
                      background: -webkit-linear-gradient(left,  rgba(255,0,0,1) 0%,rgba(255,255,0,1) 17%,rgba(0,255,0,1) 33%,rgba(0,255,255,1) 50%,rgba(0,0,255,1) 67%,rgba(255,0,255,1) 83%,rgba(255,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
                      background: linear-gradient(to right,  rgba(255,0,0,1) 0%,rgba(255,255,0,1) 17%,rgba(0,255,0,1) 33%,rgba(0,255,255,1) 50%,rgba(0,0,255,1) 67%,rgba(255,0,255,1) 83%,rgba(255,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff0000', endColorstr='#ff0000',GradientType=1 ); /* IE6-9 */
                    }
    
                    &.darkpicker{
                      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,ffffff+100 */
                      background: rgb(0,0,0); /* Old browsers */
                      background: -moz-linear-gradient(left,  rgba(0,0,0,1) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
                      background: -webkit-linear-gradient(left,  rgba(0,0,0,1) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
                      background: linear-gradient(to right,  rgba(0,0,0,1) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
                    }
                  }
    
                  &.multi{
                    > span{
                      display: inline-block;
                    }
                  }
                }
    
                &.lay2{
                  width: 50%;
                }
    
                &.lay3{
                  width: 33.33%;
                }
    
                &.lay4{
                  width: 25%;
                }
    
                &.layfull{
                  width: 100%;
                }
    
                &.clear{
                  clear: both;
                }
              }
            }
          }
        }
      }
    }
  }

  > div{
    position: relative;
    clear: both;
    padding-top: .5px;
    z-index: 0;

    > button{
      position: relative;
      background: #EEE;
      min-height: 78vh;

      &:hover{
        background: #CCC;

        > div{
          color: #FFF;
        }
      }
      
      > img{
        width: 100%;
      }

      > div{
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 20px;
        right: 20px;
        color: #999;
        text-align: center;
        font-size: 16px;
        line-height: 20px;
        padding: 40px 0;
        transition: all 200ms ease-in-out;
        -moz-transition: all 200ms ease-in-out;
        -webkit-transition: all 200ms ease-in-out;

        > svg{
          display: block;
          font-size: 80px;
          margin: 0 auto 10px auto;
        }
      }

      &.img{
        // background: transparent;
        min-height: auto;

        > div{
          color: #FFF;
          opacity: 0;
          /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.1+50,0+100 */
          background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 50%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        }

        &:hover{
          > div{
            opacity: 1;
          }
        }
      }
    }
  }
}

footer{
  // position: fixed;
  // bottom: 0;
  // left: 0;
  // right: 0;
  text-align: center;
  padding: 20px 40px 40px 40px;
  font-size: 11px;
  line-height: 15px;
  background: rgba(255, 255, 255, .9);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);

  a{
    font-weight: bold;

    &:hover{
      margin: 0 5px;
    }

    > svg{
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      margin: -3px 0 0 0;
    }
  }
}

@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){
  header{
    > div{
      > ul{
        > li{
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4){
            width: 16.66%;
          }
  
          &:nth-child(5),
          &:nth-child(6){
            width: 16.66%;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){
  header{
    > div{
      > a{
        float: none;
        margin: 0 auto 10px auto;
        width: 164px;

        > svg{
          width: 100%;
        }
      }

      > ul{
        margin: -5px;

        > li{
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6){
            width: 33.33%;
          }
        }
      }
    }
  }

  .container{
    padding: 190px 20px 20px 20px;
  }
}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){
  header{
    > div{
      > a{
        float: none;
        margin: 0 auto 10px auto;
        width: 164px;

        > svg{
          width: 100%;
        }
      }

      > ul{
        margin: -5px;

        > li{
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6){
            width: 33.33%;
          }
        }
      }
    }
  }

  .container{
    padding: 190px 20px 20px 20px;
  }

  footer{
    padding: 0 40px 20px 40px;
  }
}

@media screen and (max-width: 767px) and (orientation: portrait){
  header{
    > div{
      > a{
        float: none;
        margin: 0 auto 10px auto;
        width: 164px;

        > svg{
          width: 100%;
        }
      }

      > ul{
        margin: -5px;

        > li{
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6){
            width: 50%;
          }
        }
      }
    }
  }

  .container{
    padding: 240px 20px 20px 20px;
  }

  footer{
    padding: 0 20px 20px 20px;
  }
}

/*
@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){}

@media screen and (max-width: 767px) and (orientation: portrait){}
*/